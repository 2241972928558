import Head from "next/head";
//import { useRouter } from "next/router";
import React /*, { useEffect } */ from "react";

interface Props {
  disabled?: boolean;
}

export default function Adpage({
  disabled = false,
}: Props) {
//   const router = useRouter();

//   useEffect(() => {
//     const handleRouteChange = (url: string) => {
//       if (!disabled) {
//         (window as any).dataLayer.push({
//           event: 'pageview',
//           page: url,
//         });
//       } else {
//         console.info('GTM disabled: pretending to send a pageview for GTM')
//       }
//     }

//     router.events.on('routeChangeComplete', handleRouteChange)

//     return () => {
//       router.events.off('routeChangeComplete', handleRouteChange)
//     }
//   }, [])

  return (
    <>
      <Head>
        {!disabled && <script
            dangerouslySetInnerHTML={{
                __html:`(function(){function t(t,e,r){if(\"cookie\"===t){var n=document.cookie.split(\";\");for(var o=0;o<n.length;o++){var i=n[o].split(\"=\");if(i[0].trim()===e)return i[1]}}else if(\"localStorage\"===t)return localStorage.getItem(e);else if(\"jsVariable\"===t)return window[e];else console.warn(\"invalid uid source\",t)}function e(e,r,n){var o=document.createElement(\"script\");o.async=!0,o.src=r,e.insertBefore(o,n)}function r(r,n,o,i,a){var c,s=!1;try{var u=navigator.userAgent,f=\/Version\\\/([0-9\\._]+)(.*Mobile)?.*Safari.*\/.exec(u);f&&parseFloat(f[1])>=16.4&&(c=t(o,i,\"\"),s=!0)}catch(t){console.error(t)}var l=window[a]=window[a]||[];l.push({\"gtm.start\":(new Date).getTime(),event:\"gtm.js\"});var g=r+\"\/script.js?id=\"+n+(s?\"&enableCK=true\":\"\")+(c?\"&mcookie=\"+encodeURIComponent(c):\"\"),d=document.getElementsByTagName(\"script\")[0];e(d.parentNode,g,d)}r(\'https:\/\/tagging.fysiotherapie4all.nl\',\"GTM-WBVPZ7\",\"cookie\",\"_taggingmk\",\"dataLayer\")})();`
            }}
        /> }

        {!disabled && <script defer src="https://tagging.fysiotherapie4all.nl/user-data-minified.js"></script> }
      </Head>

        {!disabled && <noscript>
            <iframe 
                src="https://tagging.fysiotherapie4all.nl/ns.html?id=GTM-WBVPZ7" 
                height="0" 
                width="0" 
                style={{ display:'none', visibility: 'hidden'}}>
            </iframe>
        </noscript> }

        {! disabled && <script defer src="https://tagging.fysiotherapie4all.nl/adex.js"></script> }
        {! disabled && <script defer src="https://tagging.fysiotherapie4all.nl/settings.js"></script> }
    </>
  )
}
