import useSWR from 'swr'

export default function useComplaints() {
  const fetcher = (...args) => fetch(...args)
    .then(res => res.json())
    .then(posts => posts.map(post => ({
      ...post,
      title: post.title.rendered,
      link: `/${post.slug}`,
    })))
  return useSWR('/api/v1/complaints', fetcher, {
    revalidateOnMount: true,
    initialData: [],
  })
}