import { Form, InputGroup } from 'react-bootstrap';
import Button from 'atoms/button';
import { useState } from 'react';

export default function WikiSearchWidget({
  onSearchSubmitted = () => {},
  placeholder = "Zoek een pijnklacht",
}) {
  const [searchTerm, setSearchTerm] = useState('')

  const onSubmit = (term) => {
    setSearchTerm('')
    onSearchSubmitted(term)
  }

  return (
    <>
      <InputGroup>
        <Form.Label htmlFor="search-query" hidden>{placeholder}</Form.Label>
        <Form.Control
          type="text"
          name="search-query"
          placeholder={placeholder}
          aria-label={placeholder}
          aria-placeholder={placeholder}
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
          onKeyPress={event => {
            if (event.key === "Enter") {
              event.preventDefault();
              onSubmit(searchTerm)
            }
          }}
        />
        <InputGroup.Append>
          <Button
            style={{ height: '38px', marginTop: 0, paddingTop: '10px' }}
            onClick={() => onSubmit(searchTerm)}
          >Zoek</Button>
        </InputGroup.Append>
      </InputGroup>
    </>
  )
}
