import Image from 'next/image';
import {
  useState,
  useEffect,
} from 'react';
import useSpecialisms from 'swr-hooks/use-specialisms';
import useComplaints from 'swr-hooks/use-complaints';
import handleHrefClick from 'lib/handle-href-click';
import { useRouter } from 'next/router';
import Link from 'next/link'
import Button from 'atoms/button';
import WikiSearchWidget from 'molecules/wiki-search-widget';

export default function MenuBar(

) {
  const router = useRouter();

  // Sidemenu opening and closing
  const [ menuOpen, setMenuOpen ] = useState(false);

  const [ searchOpen, setSearchOpen ] = useState(false);

  function showOrHideSubmenu(e, menuItem) {
    if(menuItem.items.length) {
      e.preventDefault();

      const active = dropdownClick.title == menuItem.title.text && dropdownClick.active ? false : true

      setDropdownClick({'title': menuItem.title.text, 'active': active})

    }
  }

  useEffect(() => {
    const handleRouteChange = () => {
      setMenuOpen(false);
      setSearchOpen(false);
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  const { data: specialismItems } = useSpecialisms()
  const { data: complaintItems } = useComplaints()

  const menuItems = [
    {
      title: {
        text: "Pijnklachten",
        link: "/#veel-voorkomende-klachten",
      },
      items: complaintItems,
    },
    {
      title: {
        text: "Specialismen",
        link: "/specialismen",
      },
      items: specialismItems,
    },
    {
      title: {
        text: "Producten",
        link: "https://pijnvrij.fysiotherapie4all.nl/producten/",
        target: "_blank",
      },
      items: [
        {
          id: "boek",
          title: "Boek",
          link: "https://pijnvrij.fysiotherapie4all.nl/de-oplossing-voor-pijn/",
          target: "_blank"
        }, {
          id: "prm",
          title: "Pijn Reset Methode",
          link: "https://pijnvrij.fysiotherapie4all.nl/pijn-reset-methode/",
          target: "_blank"
        }, {
          id: "prc",
          title: "Pijn Reset Community",
          link: "https://pijnvrij.fysiotherapie4all.nl/pijn-reset-community/",
          target: "_blank"
        }, {
          id: "sup",
          title: "Supplementen",
          link: "https://pijnvrij.fysiotherapie4all.nl/supplementen/",
          items: [
            {
              id: "advies",
              title: "Advieslijn",
              link: "https://pijnvrij.fysiotherapie4all.nl/supplementen-advies/",
              target: "_blank"
            }, {
              id: "shop",
              title: "Shop",
              link: "https://pijnvrij.fysiotherapie4all.nl/supplementen/",
              target: "_blank"
            }
          ]
        }, {
          id: "spreker",
          title: "Spreker",
          link: "https://pijnvrij.fysiotherapie4all.nl/spreker-maikel-korbmacher/",
          target: "_blank"
        }, {
          id: "workshops",
          title: "Workshops",
          link: "https://pijnvrij.fysiotherapie4all.nl/workshops/",
          target: "_blank"
        }
      ]
    },
    {
      title: {
        text: "Stories",
        link: "/succesverhalen",
      },
    },
    {
      title: {
        text: "Locaties",
        link: "/locaties",
      },
    },
    {
      title: {
        text: "Inloggen",
        link: "https://forum.fysiotherapie4all.nl/login#login",
        target: "_blank",
        desktop: false,
      },
    }
  ]

  const [dropdownClick, setDropdownClick] = useState({ title: '', active: false })

  const onSearchWidgetSubmit = (term) => {
    router.push(term ? `/wiki?q=${term}` : '/wiki')
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="row justify-content-between justify-content-lg-around">
            <div className="col-6 col-md-3 col-xl-2 my-auto my-md-2">
              <Link onClick={handleHrefClick} href="/">
                <a><Image
                  src="/img/logo.png"
                  alt="Fysiotherapie4all logo"
                  width={100}
                  height={25}
                  layout="responsive"
                  objectFit="contain"
                /></a>
              </Link>
            </div>
            <div className="col-6 col-md-9 col-xl-10 my-auto d-none d-lg-block text-right py-0">
              <ul className="menu-items-lvl-1">
                {menuItems.filter(menuItem => menuItem.title.desktop !== false).map(menuItem => {
                  return (
                    <li key={`menu-item-lvl1-${menuItem.title.text}`} className="menu-item-lvl-1">
                      {menuItem.title.link && <Link onClick={handleHrefClick} href={menuItem.title.link}>
                        <a target={menuItem.title.target && menuItem.title.target}>{menuItem.title.text}</a>
                      </Link>}
                      {!menuItem.title.link && <a>{menuItem.title.text}</a>}

                      {menuItem.items && <ul className="menu-items-lvl-2">
                        {menuItem.items.map(item => {
                          return (
                            <li key={item.id} className="menu-item-lvl-2">
                              <a target={item.target} onClick={handleHrefClick} href={item.link}>
                                {menuItem.itemPrefix}{item.title}
                              </a>

                              {item.items && <ul className="menu-items-lvl-3">
                                {item.items.map(item => {
                                  return (
                                    <li key={item.id} className="menu-item-lvl-3">
                                      <a target={item.target} onClick={handleHrefClick} href={item.link}>
                                        {menuItem.itemPrefix}{item.title}
                                      </a>
                                    </li>
                                  )
                                })}
                              </ul>}
                            </li>
                          )
                        })}
                      </ul>}
                    </li>
                  );
                })}
                <li className="menu-item-lvl-1 no-hover-change ml-4">
                  <Button type="primary-no-margin" link="/locaties">Maak afspraak</Button>
                </li>
                <li className="menu-item-lvl-1 no-hover-change ml-5 mt-3 mt-xl-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search search-icon" viewBox="0 0 16 16" onClick={() => { searchOpen ? setSearchOpen(false) : setSearchOpen(true)} }>
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                  </svg>
                </li>
                <li className="menu-item-lvl-1 ml-2">
                  <a href="https://forum.fysiotherapie4all.nl/login#login" target="_blank">Inloggen</a>
                </li>
              </ul>
            </div>
            <div className="col-3 d-block d-lg-none text-right">
              <span className="openbtn" onClick={() => setMenuOpen(true)}>&#9776;</span>
            </div>
          </div>
        </div>
        <div className="sidenav d-block d-lg-none">
          <a className="closebtn" onClick={() => setMenuOpen(false)}>&times;</a>
          <ul className="menu-items-lvl-1">
            <li className="menu-item-lvl-1 text-center mx-5">
              <WikiSearchWidget onSearchSubmitted={onSearchWidgetSubmit} />
            </li>
            <li className="menu-item-lvl-1 text-center mb-4">
              <Button link="/locaties">Maak afspraak</Button>
            </li>
            {menuItems.map(menuItem => {
              return (
                <li key={`menu-item-lvl1-${menuItem.title.text}`} className={`menu-item-lvl-1 ${dropdownClick.title == menuItem.title.text ? (dropdownClick.active ? 'active' : '') : ''}`}>
                  <a target={menuItem.title.target && menuItem.title.target} onClick={!menuItem.items ? handleHrefClick : e => { showOrHideSubmenu(e, menuItem) }} href={menuItem.title.link}>{menuItem.title.text}</a>
                  {menuItem.items && <ul className="menu-items-lvl-2">
                    {menuItem.items.map(item => {
                      return (
                        <li key={item.id} className="menu-item-lvl-2">
                          <Link onClick={handleHrefClick} href={item.link}>
                            <a>
                              {menuItem.itemPrefix}{item.title}
                            </a>
                          </Link>
                          
                          {item.items && <ul className="menu-items-lvl-3">
                            {item.items.map(item => {
                              return (
                                <li key={item.id} className="menu-item-lvl-3">
                                  <a target={item.target} onClick={handleHrefClick} href={item.link}>
                                    {menuItem.itemPrefix}{item.title}
                                  </a>
                                </li>
                              )
                            })}
                          </ul>}
                        </li>
                      )
                    })}
                  </ul>}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={`searchbar d-none ${searchOpen && 'd-lg-block'} `}>
          <div className="container">
            <div className="input-parent">
              <WikiSearchWidget onSearchSubmitted={onSearchWidgetSubmit} />
            </div>
          </div>
        </div>
      </header>

      <style jsx>{`
        header {
          position: sticky;
          width: 100%;
          z-index: 999;
          background: #ffffff;
          box-shadow: 0 0 15px rgb(0 0 0 / 20%);
          top: 0;
        }

        header .container > .row {
          padding: 20px 0 20px 0;
        }

        ul.menu-items-lvl-1 {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }

        header li.menu-item-lvl-1 {
          display: inline-block;
          margin-left: 10px;
          text-align: left;
          position: relative;
        }

        header li.menu-item-lvl-1 * {
          z-index: 1;
        }

        header li.menu-item-lvl-1 a {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
          color: #102E4A;
        }

        header li.menu-item-lvl-2 a {
          color: #ffffff;
        }

        header li.menu-item-lvl-1 > a {
          padding-left: 20px;
        }

        header li.menu-item-lvl-1 a.btn-primary {
          color: #fff;
        }

        header .btn-primary {
          margin: 0 !important;
        }

        header img {
          border-radius: 0;
          box-shadow: none;
        }

        .sidenav {
          height: 100%;
          position: fixed;
          z-index: 1;
          top: 0;
          right: 0;
          background-color: #111;
          overflow-x: hidden;
          transition: 0.3s;
          padding-top: 60px;
          width: ${menuOpen ? '100%' : 0};
        }

        .sidenav a {
          padding: 8px 8px 8px 32px;
          text-decoration: none;
          font-size: 25px;
          color: #818181;
          display: block;
          transition: 0.3s;
        }

        .sidenav a:hover {
          color: #f1f1f1;
        }

        .openbtn {
          font-size: 30px;
          cursor:pointer;
          color: #102E4A;
        }

        .sidenav .closebtn {
          position: absolute;
          top: 0;
          right: 20px;
          font-size: 36px;
          margin-left: 50px;
        }

        .sidenav ul.menu-items-lvl-1 {
          padding-top: 50px;
        }

        .sidenav ul.menu-items-lvl-1 li.menu-item-lvl-1 ul.menu-items-lvl-2 {
          display: none;
        }

        .sidenav ul.menu-items-lvl-1 li.menu-item-lvl-1.active ul.menu-items-lvl-2 {
          display: block;
        }

        .sidenav li.menu-item-lvl-1 {
          display: block;
        }

        ul.menu-items-lvl-2 {
          list-style-type: none !important;
          background-color: #102E4A;
          padding: 20px;
          width: 220px;
          position: absolute;
          visibility: hidden;
          opacity: 0;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-top: 26px;
        }

        ul.menu-items-lvl-3 {
          list-style-type: none !important;
          padding: 0px 20px;
          display: none;
          visibility: hidden;
          opacity: 0;
        }

        .sidenav li.menu-item-lvl-1:last-child a {
          padding: 8px 15px;
          margin: 10px 32px !important;
        }

        li.menu-item-lvl-2 a {
          color: #ffffff;
          line-height: 32px;
        }

        @media screen and (min-width: 991px) {
          .menu-item-lvl-1:hover {
            padding-bottom: 26px;
            margin-bottom: -26px;
            padding-top: 26px;
            margin-top: -26px;
            padding-right: 20px;
            margin-right: -20px;
            background: #102E4A;
            z-index: 1;
          }
  
          .menu-item-lvl-1.no-hover-change:hover {
            padding-bottom: inherit;
            padding-top: inherit;
            margin-top: inherit;
            margin-bottom: inherit;
            padding-right: inherit;
            margin-right: inherit;
            background: inherit;
          }
  
          .menu-item-lvl-1:hover a {
            color: #ffffff !important;
          }
  
          .menu-item-lvl-1:hover > ul.menu-items-lvl-2 {
            visibility: visible;
            opacity: 1;
          }
  
          .menu-item-lvl-1:hover,
          .menu-item-lvl-1:hover > a,
          .menu-item-lvl-2:hover,
          .menu-item-lvl-2:hover > a,
          .menu-item-lvl-3:hover,
          .menu-item-lvl-3:hover > a {
            text-decoration: none;
          }

          .menu-item-lvl-2:hover > ul.menu-items-lvl-3 {
            visibility: visible;
            opacity: 1;
            display: block;
          }

          .searchbar {
            padding-bottom: 20px;
            margin-top: -10px;
            cursor: pointer;
          }

          .searchbar .input-parent {
            background-color: green !important;
            max-width: 50% !important;
            margin-left: auto;
          }
        }

        .search-icon {
          cursor: pointer;
        }

        @media screen and (max-width: 991px) {
          .sidenav {
            padding-top: 15px;
          }

          .sidenav a {
            font-size: 18px;
          }

          header .container > .row {
            padding: 10px 0;
          }

          .menu-item-lvl-1 {
            position: static;
          }

          .menu-item-lvl-1:hover,
          .menu-item-lvl-1:hover ul.menu-items-lvl-2 {
            background: transparent;
          }

          .menu-item-lvl-1 a {
            color: #ffffff !important;
          }

          .menu-item-lvl-1 ul.menu-items-lvl-2 {
            visibility: visible;
            opacity: 1;
            position: static;
            background-color: transparent;
            padding: 0 0 20px 10px;
            margin-top: 0px
          }

          .menu-item-lvl-1 ul.menu-items-lvl-2 ul.menu-items-lvl-3 {
            visibility: visible;
            opacity: 1;
            display: block;
          }

          .menu-item-lvl-1:last-child {
            margin-bottom: 40px;
            margin-left: 0 !important;
          }

          .sidenav li.menu-item-lvl-1:last-child a {
            margin-left: 16px !important;
          }

          li.menu-item-lvl-2 a {
            line-height: 26px;
          }

          /* Override the bottom margin for the sticky header on mobile */
          header .container > .row > div {
            margin-bottom: 0px;
          }
        }
      `}</style>
    </>
  )
}
