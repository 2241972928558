import "styles/global.scss";
import Head from 'next/head';
import MenuBar from 'components/menu-bar';
import Footer from 'components/footer';
import FacebookPixel from "components/analytics/FacebookPixel";
import GoogleAnalytics from "components/analytics/google-analytics";
//import GoogleTagManager from "components/analytics/google-tag-manager";
import Adpage from "components/analytics/adpage";
import Bugsnag from '../lib/bugsnag'
import EbookModal from 'molecules/ebook-modal';

const ErrorBoundary = Bugsnag.getPlugin('react')

export default function Fysiotherapie4All({ Component, pageProps }) {
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <Head>
        <title>Fysiotherapie4all</title>

        <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
        <link rel="manifest" href="/icons/site.webmanifest" />
        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta name="description" content="Alle fysio vragen beantwoord. Direct een fysiotherapeut in de buurt." />
      </Head>
      <MenuBar />
      <Adpage 
        disabled={process.env.NODE_ENV === 'development'}
      />
      <GoogleAnalytics
        disabled={process.env.NODE_ENV === 'development'}
        trackingId={process.env.NEXT_PUBLIC_GA_TRACKING_CODE}
      />
      <FacebookPixel enabled={process.env.NODE_ENV !== 'development'}>
        <Component {...pageProps} />
      </FacebookPixel>
      <EbookModal />
      <Footer />
    </ErrorBoundary>
  )
}
