import Router from 'next/router'

export default function handleHrefClick(event) {
  const url = event.target.attributes.href?.value
  const target = event.target.attributes.target?.value

  if (url && target !== '_blank') {
    event.preventDefault();
    Router.router.push(url);
  }
}