import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Popup from 'reactjs-popup';
import Cookies from 'universal-cookie';

export default function EbookModal({ 
}) {
    const cookies = new Cookies();
    const router = useRouter()

    const [open, setOpen] = useState(false);
    const closeModal = () => {
        setOpen(false);
        
        cookies.set('ebookModal', true, {
            expires: new Date(new Date().getTime() + (30*24*60*60*1000))
        });
    }
    
    useEffect(() => {
        if(! cookies.get('ebookModal') && ! router.asPath.startsWith('/chronische-pijn') && router.pathname != '/pijncheck' && router.pathname != '/locaties' && router.pathname != '/locatie/[slug]') {
            const timeId = setTimeout(() => setOpen(true), 5000);

            return () => clearTimeout(timeId);
        }
    }, []); 

    return (
        <div suppressHydrationWarning={true}>
            {process.browser && (
                <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                    <div className="modal-ebook">
                        <button className="close" onClick={closeModal}>
                        &times;
                        </button>
                        <div className="header"> 
                            <h3>Wil jij ook een pijnvrij leven?</h3>
                        </div>

                        <div className="content">
                            Download hieronder dan gratis ons e-book!

                            <img 
                                height="200"
                                src="/img/ebook-open.png" 
                            />
                        </div>
                        <div className="actions">
                            <a
                                href="https://pijnvrij.fysiotherapie4all.nl/gratis-ebook/"
                                target="_blank"
                                className="btn btn-orange"
                                onClick={closeModal}
                            >
                                Bekijk e-book
                            </a>
                        </div>
                    </div>
                </Popup>
            )}
        </div>
    )
}
