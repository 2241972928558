import { useEffect, useState } from 'react'

export const FutyWidget = ({
    disabled = false
}) => {
    const [isMounted, setMount] = useState(false);
    
    useEffect(() => {
        if(! disabled) {
            setMount(true);
        }
    }, []);

    return (
      <script
        dangerouslySetInnerHTML={{
            __html: isMounted ? `window.Futy = { key: '6509dca57e1b5' };
                (function (e, t) {
                    var n = e.createElement(t);
                    n.async = true;
                    var f = window.Promise && window.fetch ? 'modern.js' : 'legacy.js';
                    n.src = 'https://v1.widget.futy.io/js/futy-widget-' + f;
                    var r = e.getElementsByTagName(t)[0];
                    r.parentNode.insertBefore(n, r);
                })(document, 'script');` : ``
        }}
        />
    )
}