import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

interface Props {
  disabled?: boolean;
  trackingId: string;
}

export default function GoogleAnalytics({
  disabled = false,
  trackingId,
}: Props) {
  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
  const pageview = (url) => {
    (window as any).gtag("config", trackingId, {
      page_path: url
    });
  };

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (!disabled) {
        pageview(url);
      } else {
        console.info('GA disabled: pretending to send a pageview to GA')
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
  return (
    <Head>
      {!disabled && <>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingId}', {
              page_path: window.location.pathname,
            });
            `
          }}
        />
      </>}
    </Head>
  )
}
