import { useEffect } from 'react'
import { useRouter } from 'next/router'
import * as fbq from 'lib/fpixel'
import Head from 'next/head'

const handleRouteChange = () => {
  fbq.pageview()
}

const FacebookPixel = ({
  children,
  enabled = false,
}) => {
  const router = useRouter()

  useEffect(() => {
    if (enabled) {
      // This pageview only trigger first time (it is important for Pixel to have real information)
      fbq.pageview()
  
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])

  return (
    <>
      <Head>
        {enabled && <script
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${fbq.FB_PIXEL_ID}');
              fbq('init', '198827591824305');
            `,
          }}
        />}
      </Head>
      {children}
    </>
  )
}

export default FacebookPixel