import useSWR from 'swr'

export default function useSpecialisms() {
  const fetcher = (...args) => fetch(...args)
    .then(res => res.json())
    .then(posts => posts.map(post => ({
      ...post,
      title: post.title.rendered,
      link: `/specialismen/${post.slug}`,
    })))
  return useSWR('/api/v1/specialisms', fetcher, {
    revalidateOnMount: true,
    initialData: [],
  })
}